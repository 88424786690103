import React from 'react';
import { useMediaQuery } from 'react-responsive';
// modules imports:
import Navbar from '../components/Navbar';
import About from '../components/About';

const Portfolio = () => {
    const isPc = useMediaQuery({ query: '(min-width: 1200px)' });
    return (
        <div className='Portfolio'>
            <Navbar />
            <div className="portfolio-container">
                {isPc && <About />}
                <div className="portfolio-content">
                    <div className="scroll-window">
                        <div className="scroll-window-content">
                            <div className="title-scroll-win-content">
                                <h3>Mes réalisations:</h3>
                            </div>
                            <div className="project-content-scroll-win">
                                <div className="project-1">
                                    en dev
                                </div>
                                <div className="project-2">
                                    en dev
                                </div>
                                <div className="project-3">
                                    en dev
                                </div>
                                <div className="project-4">
                                    en dev
                                </div>
                                <div className="project-5">
                                    en dev
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Portfolio;