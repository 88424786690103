import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// modules imports:
import Home from './pages/Home';
import Error404 from './pages/Error404';
import Knowledges from "./pages/Knowledges";
import Portfolio from './pages/Portfolio';
import Contact from './pages/Contact';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path='/competences' element={<Knowledges />} />
        <Route path='/portfolio' element={<Portfolio />} />
        <Route path='/contact' element={<Contact />} />
        {/* Default (404 error): */}
        <Route path="*" element={<Error404 />} />
        {/* Admin pages: */}
      </Routes>
    </BrowserRouter>
  );
};

export default App;
