import React from 'react';
import { NavLink } from 'react-router-dom';

const Navbar = () => {
    return (
        <div className='Navbar'>
            <ul>
                <li>
                    <NavLink to='/' activeClassName="active">
                        Accueil
                    </NavLink>
                </li>
                <li>
                    <NavLink to='/competences' activeClassName="active">
                        Compétences
                    </NavLink>
                </li>
                <li>
                    <NavLink to='/portfolio' activeClassName="active">
                        Portfolio
                    </NavLink>
                </li>
                <li>
                    <NavLink to='/contact' activeClassName="active">
                        Contact
                    </NavLink>
                </li>
            </ul>
        </div>
    );
};

export default Navbar;