import React from 'react';
import { NavLink } from 'react-router-dom';

const About = () => {
    return (
        <div className='About'>
            <div className="title">
                <img src="./media/favicon.svg" alt="" />
            </div>
            <div className="container">
                <p align="justify"> {/* je ne sais pas si je justify mon texte ou l'aligne a gauche ou droite ou center */}
                Bonjour et bienvenue sur mon CV virtuel. Moi, c'est Noé, j'ai 17 ans. Je rédige cette rubrique avec la conviction que ces simples paragraphes ont une importance cruciale afin que vous puissiez comprendre de la meilleure des manières la personne que vous avez en face de vous.<br /><br />
                Je suis débutant en développement informatique, que ce soit en développement web (front et back-end) ou en développement de jeux vidéo (intégration de fonctionnalités pour créer du gameplay tout en maintenant des performances optimales afin de garantir une expérience utilisateur toujours satisfaisante), entre autres.<br /><br />
                Mes centres d'intérêts sont : l'informatique (évidemment :) ), la cybersécurité (technico-légale et gestion de crise), la communication, le marketing, l'e-sport (gestion/management), et la gestion de l'UX sous toutes ses formes.<br /><br />
                Sinon, j'ai des centres d'intérêts sans rapport direct avec l'informatique, tels que : la randonnée et l'escalade, la cuisine et la boulangerie/pâtisserie, le bricolage.<br /><br />
                Dans la rubrique <NavLink to='/competences' activeClassName='active'>compétences</NavLink> de mon site, vous pouvez retrouver les technologies que je maîtrise (certaines plus que d'autres). Mais ici, j'ai envie de vous parler des sujets que je souhaite apprendre et/ou maîtriser plus en profondeur : l'électronique, la programmation bas niveau, les réseaux informatiques, le développement d'OS, et le design UI.
                </p>
            </div>
        </div>
    );
};

export default About;