import React from 'react';
// modules imports:

const Error404 = () => {
    return (
        <div className='Error404'>
            <h1>error404.jsx</h1>
            <div className="error404-content">
                
            </div>
        </div>
    );
};

export default Error404;