import React from 'react';
import { useMediaQuery } from 'react-responsive';
// Modules imports:
import Navbar from '../components/Navbar';
import About from '../components/About';

const Knowledges = () => {
    const isPc = useMediaQuery({ query: '(min-width: 1200px)' });
    return (
        <div className='Knowledges'>
            <Navbar />
            <div className="knowledges-container">
                {isPc && <About />}
                <div className="knowledges-content">
                    <div className="languages">
                        <h3>Languages</h3>
                        <div className="languages-container">
                            <table>
                                <tr>
                                    <td>
                                        <div className="python" >
                                            <img src="./media/techs/python.svg" alt="" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="lua">
                                            <img src="./media/techs/lua.svg" alt="" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="php">
                                            <img src="./media/techs/php_dark.svg" alt="" />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <div className="sass">
                                        <img src="./media/techs/sass.svg" alt="" />
                                    </div>
                                </tr>
                            </table> 
                        </div>
                    </div>
                    <div className="technos">
                        <h3>Technologies</h3>
                        <div className="technos-container">
                            <table>
                                <tr>
                                    <td>
                                        <div className="react">
                                            <img src="./media/techs/react.svg" alt="" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="flutter">
                                            <img src="./media/techs/flutter.svg" alt="" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="nodejs">
                                            <img src="./media/techs/nodejs-icon.svg" alt="" />
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div className="softs">
                        <h3>Logiciels/OS</h3>
                        <div className="softs-container">
                            <table>
                                <tr>
                                    <td>
                                        <div className="vscode">
                                            <img src="./media/techs/vscode.svg" alt="" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="visual-studio">
                                            <img src="./media/techs/visual-studio.svg" alt="" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="figma">
                                            <img src="./media/techs/figma.svg" alt="" />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="windows">
                                            <img src="./media/techs/windows.svg" alt="" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="linux">
                                            <img src="./media/techs/linux.svg" alt="" />
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Knowledges;
