import React from 'react';
import { useMediaQuery } from 'react-responsive';
// modules import:
import Navbar from '../components/Navbar';
import About from '../components/About';

const Contact = () => {
    const isPc = useMediaQuery({ query: '(in-width: 1200px)' });
    return (
        <div className='Contact'>
            <Navbar />
            <div className="contact-container">
                {isPc && <About />}
                <div className="contact-content">
                    <div className="contact-rubrique">
                        <div className="title">
                            <h3>mes informations de contact</h3>
                        </div>
                        <div className="container">
                            <p>
                                E-Mail : noehuart.pro@gmail.com
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;
