import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "./styles/index.scss";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// signature console:
console.warn(
  "%cDev with ❤️ by iko (Huart Noé).",
  "color: orange; font-size: 24px; font-weight: bold; border: 2px solid #fff; border-radius: 20px; padding: 10px;"
);
